/* Code based on https://github.com/nimiq/web-share-shim/tree/master, licensed under MIT */
.webShare {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", arial, sans-serif;
    font-weight: 400;
    font-size: 13px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
    position: fixed;
    top: -10px;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0);
    transition: background .4s;
    padding: 8px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    z-index: 100000;
}

.webShareTitle {
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 0.12em;
    opacity: 0.7;
}

.webShareContainer {
    max-width: 366px;
    width: 100%;
    box-sizing: border-box;
    margin: 0 auto 8px;
    background: #F8F8F8;
    border-radius: 12px;
    box-shadow: rgba(0, 0, 0, 0.5) 0 2px 4px;
    padding: 16px 23px;
    text-align: left;
    color: black;
    transform: translateY(10vh);
    opacity: 0;
    transition: transform .4s, opacity .4s;
}

.webShareCancel {
	composes: webShareContainer;
	composes: center from '../common.module.css';
    color: #0076FF;
    font-size: 16px;
    cursor: pointer;
}

.webShareItem {
	composes: center from '../common.module.css';
    margin: 16px 8px;
    display: inline-block;
    text-decoration: none !important;
    color: black !important;
    cursor: pointer;
    width: 64px;
}

.webShareItem img {
    background-color: white;
    border-radius: 12px;
    padding: 12px;
    color: black;
    font-size: 32px;
    width: 32px;
    height: 32px;
    background-position: center;
    background-repeat: no-repeat;
    margin: 0 auto;
}

.webShareItem span {
	display: block;
}

.webShareItemDesc {
	composes: center from '../common.module.css';
    margin-top: 4px;
}

.webShareReady {
	composes: webShare;
    background: rgba(0, 0, 0, 0.4);
}
.webShareReady .webShareContainer {
	opacity: 1;
    transform: translateY(0px);
}
